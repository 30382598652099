import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, Button, Paper } from "@mui/material";

import BoltIcon from "@mui/icons-material/Bolt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CompareIcon from "@mui/icons-material/Compare";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ImageIcon from "@mui/icons-material/Image";
import * as amplitude from "@amplitude/analytics-browser";
import { Helmet } from "react-helmet";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AIExplorerPreview from "views/AIExplorer/AIExplorerHomePreview";

const PricingExplanation = () => (
  <div className="bg-[#F0F7FF] p-4 rounded-lg mb-4 flex items-start mt-4">
    <p className="text-xl text-[#3E3E3E]">
      AI model pricing is straightforward and transparent. Choose any model and
      pay only for what you use. Our <strong>Free</strong> plan has no
      subscriptions or commitments - just simple, usage-based pricing.
    </p>
  </div>
);

const pricingData = [
  {
    parent: "OpenAI",
    models: [
      { name: "GPT 3.5 Turbo - $", baseInput: 0.0005, baseOutput: 0.0015 },
      { name: "GPT 4 - $$$", baseInput: 0.03, baseOutput: 0.06 },
      { name: "GPT 4 Turbo - $$", baseInput: 0.01, baseOutput: 0.03 },
      { name: "GPT 4o - $$", baseInput: 0.0025, baseOutput: 0.01 },
      { name: "GPT 4o mini - $", baseInput: 0.00015, baseOutput: 0.0006 },
    ],
  },
  {
    parent: "Google",
    models: [
      { name: "Gemini 1.5 Pro - $$$", baseInput: 0.0035, baseOutput: 0.0105 },
      { name: "Gemini 1.5 Flash - $", baseInput: 0.00035, baseOutput: 0.00105 },
    ],
  },
  {
    parent: "Amazon",
    models: [
      {
        name: "Titan Text G1 - Lite - $",
        baseInput: 0.0003,
        baseOutput: 0.0004,
      },
      {
        name: "Titan Text G1 - Express - $",
        baseInput: 0.0008,
        baseOutput: 0.0016,
      },
    ],
  },
  {
    parent: "AI21 Labs",
    models: [
      { name: "Jurassic-2 Ultra - $$", baseInput: 0.0188, baseOutput: 0.0188 },
      { name: "Jurassic-2 Mid - $$", baseInput: 0.0125, baseOutput: 0.0125 },
      { name: "Jamba Instruct - $", baseInput: 0.0005, baseOutput: 0.0007 },
      { name: "Jamba 1.5 Mini - $", baseInput: 0.0002, baseOutput: 0.0004 },
      { name: "Jamba 1.5 Large - $$", baseInput: 0.002, baseOutput: 0.008 },
    ],
  },
  {
    parent: "Meta",
    models: [
      {
        name: "Llama 3.2 1B Instruct - $",
        baseInput: 0.0001,
        baseOutput: 0.0001,
      },
      {
        name: "Llama 3.2 3B Instruct - $",
        baseInput: 0.00015,
        baseOutput: 0.00015,
      },
      {
        name: "Llama 3 8B Instruct - $",
        baseInput: 0.0003,
        baseOutput: 0.0006,
      },
      {
        name: "Llama 3 70B Instruct - $$",
        baseInput: 0.00265,
        baseOutput: 0.0035,
      },
    ],
  },
  {
    parent: "Cohere",
    models: [
      { name: "Command - $$", baseInput: 0.0015, baseOutput: 0.002 },
      { name: "Command Light - $", baseInput: 0.0003, baseOutput: 0.0006 },
    ],
  },
  {
    parent: "Mistral AI",
    models: [
      {
        name: "Mistral 7B Instruct - $",
        baseInput: 0.00015,
        baseOutput: 0.0002,
      },
      {
        name: "Mistral 8x7B Instruct - $",
        baseInput: 0.00045,
        baseOutput: 0.0007,
      },
    ],
  },
  {
    parent: "Anthropic",
    models: [
      { name: "Claude 2.1 - $$$", baseInput: 0.008, baseOutput: 0.024 },
      { name: "Claude 3.5 Sonnet - $$$", baseInput: 0.003, baseOutput: 0.015 },
      { name: "Claude Instant - $$", baseInput: 0.0008, baseOutput: 0.0024 },
      { name: "Claude 3.5 Haiku - $", baseInput: 0.001, baseOutput: 0.005 },
    ],
  },
];

const formatPrice = (price) => `$${price.toFixed(5)}`;

const ModelCard = ({ parent, models, defaultExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const expandPricing = (value) => {
    setIsExpanded(value);
    amplitude.track("View Pricing - " + parent + " - " + value);
  };

  return (
    <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
      <div
        className="flex items-center justify-between p-4 cursor-pointer bg-[#F0F7FF]"
        onClick={() => expandPricing(!isExpanded)}
      >
        <h3 className="text-lg font-semibold text-[#1975D2]">{parent}</h3>
        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
      {isExpanded && (
        <div className="p-4 overflow-x-auto">
          <table className="w-full min-w-max">
            <thead>
              <tr className="border-b">
                <th className="text-left py-2 px-3">Model</th>
                <th className="text-right py-2 px-3">Input Cost</th>
                <th className="text-right py-2 px-3">Output Cost</th>
              </tr>
            </thead>
            <tbody>
              {models.map((model, index) => (
                <tr key={index} className="border-b last:border-b-0">
                  <td className="py-2 px-3">{model.name}</td>
                  <td className="text-right py-2 px-3">
                    {formatPrice(model.baseInput * 1.5)}
                  </td>
                  <td className="text-right py-2 px-3">
                    {formatPrice(model.baseOutput * 1.5)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const faqData = [
  {
    question: "How do I pay for using AI models?",
    answer:
      "Users pay for AI usage directly from their wallet balance, with charges based on the number of input and output tokens processed.",
  },
  {
    question: "Is there a minimum top-up amount for the wallet balance?",
    answer: "Yes, a minimum of $10 is required to top up the wallet balance.",
  },
  {
    question: "Can I use multiple models simultaneously?",
    answer:
      "Yes, users can utilize and compare multiple AI model responses simultaneously and are charged accordingly for each model's usage. As of now, a max of 6 models can be compared per prompt request.",
  },
  {
    question: "What are input and output tokens?",
    answer:
      "Input tokens represent the text you send to the AI model (your prompt or question). Output tokens are the text generated by the AI in response. Both are measured in units called 'tokens', which are pieces of words.",
  },
  {
    question: "How are tokens calculated for my prompts and responses?",
    answer:
      "Tokens are calculated based on the number of characters in your text. On average, 1 token is about 4 characters or 0.75 words in English. For example, 'Hello, world!' is 3 tokens. The exact token count can vary based on the specific text and the tokenization method used by each AI model.",
  },
  {
    question: "How can I estimate the cost of my usage?",
    answer:
      "To estimate costs, consider the length of your prompts (input) and the expected length of responses (output). You can use the pricing table to see the cost per 1000 tokens for each model. For a rough estimate, assume 750 words is about 1000 tokens. Remember, actual token count may vary based on the specific content.",
  },
  {
    question: "Are there any additional fees besides the per-token cost?",
    answer:
      "No, there are no hidden fees or additional costs. You only pay for the tokens you use at the rates shown in our pricing table. There are no subscription fees or minimum usage requirements.",
  },
];

const Pricing = () => {
  const [expanded, setExpanded] = useState(false);
  const [pricingContent, setPricingContent] = useState({
    Free: {
      price: "$0 per month",
      subtitle: "Start comparing AI models instantly",
      features: [
        {
          icon: CompareIcon,
          text: "Compare responses from different AI models side-by-side",
        },
        {
          icon: ImageIcon,
          text: "Upload and analyze images using advanced AI vision capabilities",
        },
        {
          icon: AutoAwesomeIcon,
          text: "Continue your chat with any AI model, individually or in parallel",
        },
        { icon: BoltIcon, text: "Access to multiple AI models" },
        {
          icon: AccountBalanceWalletIcon,
          text: "Pay-as-you-go with top-up wallet",
        },
        { icon: CreditCardIcon, text: "No credit card required for sign up" },
        { icon: ShareIcon, text: "Share your AI conversations publicly" },
        {
          icon: ThumbUpOffAltIcon,
          text: "Vote for a response as helpful or not helpful",
        },
      ],
      cta: "Get Started for Free",
      isSelected: true,
    },
    Advanced: {
      price: "Coming Soon",
      subtitle: "Enhanced features for power users",
      features: [
        {
          icon: BoltIcon,
          text: "Priority access to latest AI models and more...",
        },
      ],
      cta: "",
      isSelected: false,
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    amplitude.track("FAQ - " + panel + " - " + isExpanded);
  };

  const handleTeirSelection = (elm) => {
    const tmp = { ...pricingContent };
    Object.keys(tmp).forEach((e, i) => {
      if (e === elm) {
        tmp[e].isSelected = true;
      } else {
        tmp[e].isSelected = false;
      }
      return e;
    });
    setPricingContent(tmp);
  };

  const handleClick = () => {
    amplitude.track("Click - Get Started for Free");
  };

  return (
    <Box className="sm:px-8 px-4 py-10">
      <Helmet>
        <title>Pricing | thisorthis.ai - AI Model Comparison Platform</title>
        <meta
          name="description"
          content="Explore flexible pricing options for thisorthis.ai, the leading side-by-side AI model comparison platform. Find the perfect plan to suit your needs and budget."
        />
        <meta
          name="keywords"
          content="AI comparison pricing, machine learning costs, AI evaluation plans, thisorthis.ai pricing"
        />
      </Helmet>
      <h2 className="text-2xl  text-[#3E3E3E] font-semibold">Pricing</h2>
      <PricingExplanation />

      <div className="flex justify-around sm:mt-12 mt-2 w-full">
        <div className="pricing-scroll-container custom-scrollbar">
          {Object.entries(pricingContent).map(([tier, content], i) => (
            <div
              key={`pricing-tier-${i}`}
              className={`flex flex-col py-6 px-8 border border-solid border-[#D3E9FF] rounded-lg sm:w-[350px] max-lg:w-[90%] shrink-0 cursor-pointer ${
                content.isSelected ? "bg-[#1975D2]" : "bg-white"
              }`}
            >
              <h3
                className={`text-2xl font-bold ${content.isSelected ? "text-white" : "text-[#1975D2]"}`}
              >
                {tier}
              </h3>
              <h4
                className={`text-3xl font-bold my-4 ${content.isSelected ? "text-white" : "text-[#3E3E3E]"}`}
              >
                {content.price}
              </h4>
              <p
                className={`text-xl mb-6 ${content.isSelected ? "text-white" : "text-[#3E3E3E]"}`}
              >
                {content.subtitle}
              </p>
              <div className="space-y-4 mb-8">
                {content.features.map((feature, index) => (
                  <div key={index} className="flex items-center">
                    <feature.icon
                      className={`${content.isSelected ? "text-white" : "text-[#1975D2]"} mr-3`}
                      style={{ fontSize: 20 }}
                    />
                    <p
                      className={`${content.isSelected ? "text-white" : "text-[#3E3E3E]"} text-sm`}
                    >
                      {feature.text}
                    </p>
                  </div>
                ))}
              </div>
              {content.cta && (
                <NavLink to="/">
                  <Button
                    className={`mt-auto font-semibold text-lg py-3 w-full ${
                      content.isSelected
                        ? "bg-white text-[#1975d2]"
                        : "bg-[#2095f3] text-white"
                    } hover:bg-[#2095f3] hover:text-white transition-colors duration-300`}
                    onClick={handleClick}
                  >
                    {content.cta}
                  </Button>
                </NavLink>
              )}
            </div>
          ))}
          <style jsx>{`
            .pricing-scroll-container {
              display: flex;
              overflow-x: auto;
              gap: 24px;
              padding: 24px;
            }
            @media (max-width: 1024px) {
              .pricing-scroll-container {
                white-space: nowrap;
                overflow-x: auto;
              }
              .pricing-scroll-container > div {
                display: inline-block;
                white-space: normal;
              }
            }
          `}</style>
        </div>
      </div>
      <div className="border border-solid border-[#CAE4FF] rounded-lg w-full sm:p-8 p-4 mt-12">
        <h2 className="text-2xl text-[#3E3E3E] font-semibold">Usage</h2>
        <p className="sm:text-xl text-lg text-[##3E3E3E] font-normal mt-4">
          Users can top up their wallet balance and utilize any model from any
          tier based on their needs. The pricing page serves as a reference
          point, helping users understand the cost implications of using
          specific models. Users are charged from their wallet balance based on
          the number of input and output tokens processed by the selected model.
        </p>
      </div>
      <div className="border border-solid border-[#CAE4FF] rounded-lg w-full sm:p-8 p-4  my-12 ">
        <h2 className="text-2xl  text-[#3E3E3E] font-semibold">Key Points</h2>
        <p className="sm:text-xl text-lg  text-[##3E3E3E] font-normal mt-4">
          <li>No subscription or membership plans are required.</li>
          <li>
            Users have the flexibility to use any model without any commitments.{" "}
          </li>
          <li>
            Wallet balance can be topped up at any time, providing seamless
            access to AI models whenever needed.
          </li>
        </p>
      </div>
      <div className="border border-solid border-[#CAE4FF] rounded-lg w-full sm:p-8 p-4  my-12 ">
        <h2 className="text-2xl  text-[#3E3E3E] font-semibold">
          AI Model Pricing
        </h2>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="sm:text-xl text-lg text-[#3E3E3E] font-normal mt-8 mb-4">
            Costs are per 1000 tokens
          </p>
          {pricingData.map((item, index) => (
            <ModelCard
              key={index}
              parent={item.parent}
              models={item.models}
              defaultExpanded={item.parent === "OpenAI"}
            />
          ))}
          <p className="text-sm text-gray-600 mt-4 mb-8">
            *All prices are in USD. Our costs are subject to change.
          </p>
        </div>
      </div>
      <h2 className="text-2xl  text-[#3E3E3E] font-semibold">
        Frequently Asked Questions (FAQs)
      </h2>
      <div className="mt-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-12">
          {faqData.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
              className={`rounded-xl ${index === 0 ? "mt-0" : "mt-4"} ${
                index === faqData.length - 1 ? "mb-0" : "mb-4"
              }`}
              component={Box}
              disableGutters
              sx={{
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}bh-content`}
                id={`panel${index + 1}bh-header`}
                className={`bg-[#D3E9FF] border-0 py-2 ${
                  index === 0 ? "rounded-tl-xl rounded-tr-xl" : ""
                } ${
                  index === faqData.length - 1
                    ? "rounded-bl-xl rounded-br-xl"
                    : ""
                }`}
              >
                <h3 className="text-[#3E3E3E] text-base font-medium">
                  Q: {item.question}
                </h3>
              </AccordionSummary>
              <AccordionDetails className="py-4 bg-[#F9FCFF]">
                <p className="text-base font-normal text-[#3E3E3E]">
                  {item.answer}
                </p>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
      <AIExplorerPreview />
    </Box>
  );
};

export default Pricing;
