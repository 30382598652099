import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useSelector, useDispatch } from "react-redux";
import { MY_BALANCE } from "store/actions";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Chip, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import Topup from "views/payment/Topup";

// project imports
import MainCard from "ui-component/cards/MainCard";

// assets
import { IconCurrencyDollar } from "@tabler/icons-react";

// ==============================|| NOTIFICATION ||============================== //

const BalanceSection = () => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const balanceUpdated = useSelector(
    (state) => state.customization.balanceUpdated
  );

  const [currentBalance, setCurrentBalance] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userSerial, setUserSerial] = useState(null);
  const [userSub, setUserSub] = useState(null);
  const tab768 = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    setIsLoading(true);
    const params = new URLSearchParams(location.search);
    const success = params.get("success");

    const getBalance = async () => {
      try {
        const result = await handleFetchUserAttributes();
        const idToken = await getIdToken();

        // Store fetched values in component state
        setUserSerial(result.userSerial);
        setUserSub(result.sub);

        // Get User Type and Model Details from a single GET API
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "/user/balance",
          {
            params: {
              x_user_ser: result.userSerial,
              sub_id: result.sub,
            },
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const new_balance = response.data.balance;
        setCurrentBalance(new_balance);
        dispatch({ type: MY_BALANCE, currentBalance: new_balance });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching balance", error);
        setIsLoading(false);
      }
    };

    if (success === "true" || balanceUpdated) {
      // Perform refresh logic here
      setTimeout(() => {
        getBalance();
      }, 3000);
    } else {
      getBalance();
    }

    // Call the async function when the component mounts
  }, [balanceUpdated]);

  async function getIdToken() {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      return idToken;
    } catch (error) {
      console.log("Error getting ID token:", error);
      return null;
    }
  }

  async function handleFetchUserAttributes() {
    try {
      const userAttributes = await fetchUserAttributes();
      return {
        userSerial: userAttributes["custom:user_serial"],
        sub: userAttributes.sub,
      };
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box>
      {isLoading ? (
        <Skeleton variant="rounded" width={150} height={25} />
      ) : !tab768 ? (
        <div className="w-full flex items-center bg-[#F1F8FF] pl-4 rounded-[2em]">
          <h5 className="text-sm font-semibold text-[#336FD6] ">
            &#36; {currentBalance}
          </h5>
          <Topup isOpen={false} />
        </div>
      ) : (
        <div className="w-full flex items-center bg-[#F1F8FF] pl-2 pr-2 rounded-[2em]">
          <h6 className="text-sm font-semibold text-[#336FD6] ">
            &#36; {currentBalance}
          </h6>
        </div>
      )}
    </Box>
  );
};

export default BalanceSection;
