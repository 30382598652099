import { Box } from "@mui/material";

const Terms = () => {
  return (
    <Box className="sm:px-8 px-4 py-10">
      <div className="policy-class">
        <p align="center">
          <strong>TERMS &amp; CONDITIONS</strong>
        </p>
        <p align="center">Updated at July 7th, 2024</p>
        <h3>General Terms</h3>
        <p>
          By accessing and placing an order with thisorthis.ai, you confirm that
          you are in agreement with and bound by the terms of service contained
          in the Terms &amp; Conditions outlined below. These terms apply to the
          entire website and any email or other type of communication between
          you and thisorthis.ai.
        </p>
        <p>
          Under no circumstances shall thisorthis.ai team be liable for any
          direct, indirect, special, incidental or consequential damages,
          including, but not limited to, loss of data or profit, arising out of
          the use, or the inability to use, the materials on this site, even if
          thisorthis.ai team or an authorized representative has been advised of
          the possibility of such damages. If your use of materials from this
          site results in the need for servicing, repair or correction of
          equipment or data, you assume any costs thereof.
        </p>
        <p>
          thisorthis.ai will not be responsible for any outcome that may occur
          during the course of usage of our resources. We reserve the rights to
          change prices and revise the resources usage policy in any moment.
        </p>
        <h3>License</h3>
        <p>
          thisorthis.ai grants you a revocable, non-exclusive, non-transferable,
          limited license to download, install and use the website strictly in
          accordance with the terms of this Agreement.
        </p>
        <p>
          These Terms &amp; Conditions are a contract between you and
          thisorthis.ai (referred to in these Terms &amp; Conditions as
          "thisorthis.ai", "us", "we" or "our"), the provider of the
          thisorthis.ai website and the services accessible from the
          thisorthis.ai website (which are collectively referred to in these
          Terms &amp; Conditions as the "thisorthis.ai Service").
        </p>
        <p>
          You are agreeing to be bound by these Terms &amp; Conditions. If you
          do not agree to these Terms &amp; Conditions, please do not use the
          thisorthis.ai Service. In these Terms &amp; Conditions, "you" refers
          both to you as an individual and to the entity you represent. If you
          violate any of these Terms &amp; Conditions, we reserve the right to
          cancel your account or block access to your account without notice.
        </p>
        <h3>Definitions and key terms</h3>
        <p>
          To help explain things as clearly as possible in this Terms &amp;
          Conditions, every time any of these terms are referenced, are strictly
          defined as:
        </p>
        <p>
          ● Cookie: small amount of data generated by a website and saved by
          your web browser. It is used to identify your browser, provide
          analytics, remember information about you such as your language
          preference or login information.
        </p>
        <p>
          ● Company: when this terms mention “Company,” “we,” “us,” or “our,” it
          refers to Nisq Technologies Inc., (580 The East Mall, ON M9B 4A7),
          that is responsible for your information under this Terms &amp;
          Conditions.
        </p>
        <p>
          ● Country: where thisorthis.ai or the owners/founders of thisorthis.ai
          are based, in this case is Canada
        </p>
        <p>
          ● Device: any internet connected device such as a phone, tablet,
          computer or any other device that can be used to visit thisorthis.ai
          and use the services.
        </p>
        <p>
          ● Service: refers to the service provided by thisorthis.ai as
          described in the relative terms (if available) and on this platform.
        </p>
        <p>
          ● Third-party service: refers to advertisers, contest sponsors,
          promotional and marketing partners, and others who provide our content
          or whose products or services we think may interest you. ● Website:
          thisorthis.ai’s site, which can be accessed via this URL:
          https://www.thisorthis.ai ● You: a person or entity that is registered
          with thisorthis.ai to use the Services.
        </p>
        <h3>Restrictions</h3>
        <p>You agree not to, and you will not permit others to:</p>
        <p>
          ● License, sell, rent, lease, assign, distribute, transmit, host,
          outsource, disclose or otherwise commercially exploit the website or
          make the platform available to any third party.
        </p>
        <p>
          ● Modify, make derivative works of, disassemble, decrypt, reverse
          compile or reverse engineer any part of the website.
        </p>
        <p>
          ● Remove, alter or obscure any proprietary notice (including any
          notice of copyright or trademark) of thisorthis.ai or its affiliates,
          partners, suppliers or the licensors of the website.
        </p>
        <h3>Disclaimer</h3>
        <h2>1. Source of Responses</h2>
        <p>
          ● All responses generated by thisorthis.ai are obtained through direct
          access to various AI models provided by reputable organizations,
          including OpenAI, Google, AWS, Meta, Cohere, AI21 Labs, Mistal AI, and
          Amazon.
        </p>
        <p>
          ● It should be noted that this list of providers is not exhaustive,
          and additional models may be utilized in the future that are not part
          of the current list.
        </p>
        <p>
          ● thisorthis.ai acts solely as a platform facilitating access to these
          AI models. As such, we bear no liability for the content or accuracy
          of responses generated by these models.
        </p>
        <h2>2. Default Model Settings</h2>
        <p>
          ● The AI models utilized by thisorthis.ai are typically configured
          with default settings for parameters such as temperature, top p, and
          top k values.{" "}
        </p>
        <p>
          ● While users have the option to adjust these parameters through
          advanced features, it is important to understand that the default
          settings are provided by the models themselves and are based on their
          respective algorithms and training data.{" "}
        </p>
        <p>
          ● Selecting advanced options may alter the output of the models, but
          it is essential to note that these adjustments do not guarantee
          specific types of output, such as "Creative," "Balanced," or
          "Informative."{" "}
        </p>
        <p>
          ● The interpretation of these parameter values may vary between
          different models, and thisorthis.ai does not assume any responsibility
          for the results produced as a result of these adjustments.{" "}
        </p>
        <h2>3. No Guarantees on Output</h2>
        <p>
          ● While thisorthis.ai strives to provide users with high-quality and
          diverse responses, it is important to recognize the inherent
          limitations of AI technology.
        </p>
        <p>
          ● The nature of AI-generated content means that responses may vary in
          terms of accuracy, relevance, and coherence.
        </p>
        <p>
          ● Users should understand that thisorthis.ai cannot guarantee the
          accuracy, reliability, or suitability of any generated content for
          specific purposes.
        </p>
        <p>
          ● Furthermore, the AI models utilized by thisorthis.ai are continually
          evolving, and as such, past performance or behavior of the models may
          not be indicative of future results.
        </p>
        <h2>4. User Responsibility</h2>
        <p>
          ● Users of thisorthis.ai are responsible for evaluating the
          suitability and accuracy of generated content for their intended
          purposes.
        </p>
        <p>
          ● It is recommended that users exercise caution and critical judgment
          when utilizing AI-generated content, particularly in sensitive or
          high-stakes contexts.
        </p>
        <p>
          ● thisorthis.ai encourages users to verify information obtained from
          AI-generated responses through independent research or consultation
          with qualified professionals.
        </p>
        <p>
          ● By using thisorthis.ai, users acknowledge and accept the inherent
          risks associated with AI-generated content and agree to use the
          platform responsibly and in compliance with all applicable laws and
          regulations.
        </p>
        <h2>5. Feedback and Improvement</h2>
        <p>
          ● thisorthis.ai values user feedback and actively works to improve the
          platform and the quality of generated responses.
        </p>
        <p>
          ● Users are encouraged to report any inaccuracies, errors, or concerns
          regarding generated content to thisorthis.ai for review and potential
          resolution.
        </p>
        <p>
          ● Continuous feedback from users helps thisorthis.ai refine its
          algorithms, enhance the user experience, and maintain the integrity of
          the platform.
        </p>
        <h3>Payment</h3>
        <p>
          Payments for services on thisorthis.ai are made through Stripe,
          ensuring a secure and reliable transaction process. Our payment system
          operates on a wallet top-up basis, where users add funds to their
          wallet and use these funds to pay for services on a pay-as-you-go
          model. This approach provides flexibility, allowing users to control
          their spending based on their usage needs. It is important to note
          that the wallet balance cannot be refunded once the funds are added.
          The funds in the wallet can only be utilized to generate responses
          from various AI models available on the platform.
        </p>
        <p>
          We are continually enhancing our offerings, and in the future, we plan
          to introduce subscription services. These subscription plans will
          cater to advanced usage scenarios, offering various tiers and features
          to meet diverse user requirements. Subscription services will provide
          additional benefits and options, making it easier for users to access
          and leverage advanced functionalities of the AI models.
        </p>
        <p>
          If you register to any of our recurring payment plans, you agree to
          pay all fees or charges to your account for the Service in accordance
          with the fees, charges and billing terms in effect at the time that
          each fee or charge is due and payable. Unless otherwise indicated in
          an order form, you must provide thisorthis.ai with a valid credit card
          (Visa, MasterCard, or any other issuer accepted by us) (“Payment
          Provider”) as a condition to signing up for the Premium plan. Your
          Payment Provider agreement governs your use of the designated credit
          card account, and you must refer to that agreement and not these Terms
          to determine your rights and liabilities with respect to your Payment
          Provider. By providing thisorthis.ai with your credit card number and
          associated payment information, you agree that thisorthis.ai is
          authorized to verify information immediately, and subsequently invoice
          your account for all fees and charges due and payable to thisorthis.ai
          hereunder and that no additional notice or consent is required. You
          agree to immediately notify thisorthis.ai of any change in your
          billing address or the credit card used for payment hereunder.
          thisorthis.ai reserves the right at any time to change its prices and
          billing methods, either immediately upon posting on our Site or by
          e-mail delivery to your organization’s administrator(s).
        </p>
        <p>
          Any attorney fees, court costs, or other costs incurred in collection
          of delinquent undisputed amounts shall be the responsibility of and
          paid for by you.
        </p>
        <p>
          No contract will exist between you and thisorthis.ai for the Service
          until thisorthis.ai accepts your order by a confirmatory e-mail,
          SMS/MMS message, or other appropriate means of communication.
        </p>
        <p>
          You are responsible for any third-party fees that you may incur when
          using the Service.
        </p>
        <h3>Return and Refund Policy</h3>
        <p>
          Wallet top-ups are a pay-as-you-go model and the balance cannot be
          refunded. The balance in your wallet can only be used to generate
          responses from various AI models. In the future, subscription services
          will be available for advanced usage, tiers, and features.
        </p>
        <p>
          As with any shopping experience, there are terms and conditions that
          apply to transactions at thisorthis.ai. We’ll be as brief as our
          attorneys will allow. The main thing to remember is that by placing an
          order or making a purchase at thisorthis.ai, you agree to the terms
          along with thisorthis.ai’s Privacy Policy.
        </p>
        <p>
          If, for any reason, You are not completely satisfied with any good or
          service that we provide, don't hesitate to contact us and we will
          discuss any of the issues you are going through with our product.
        </p>
        <h3>Your Suggestions</h3>
        <p>
          Any feedback, comments, ideas, improvements or suggestions
          (collectively, "Suggestions") provided by you to thisorthis.ai with
          respect to the website shall remain the sole and exclusive property of
          thisorthis.ai.
        </p>
        <p>
          thisorthis.ai shall be free to use, copy, modify, publish, or
          redistribute the Suggestions for any purpose and in any way without
          any credit or any compensation to you.
        </p>
        <h3>Your Consent</h3>
        <p>
          We've updated our Terms &amp; Conditions to provide you with complete
          transparency into what is being set when you visit our site and how
          it's being used. By using our website, registering an account, or
          making a purchase, you hereby consent to our Terms &amp; Conditions.
        </p>
        <h3>Links to Other Websites</h3>
        <p>
          This Terms &amp; Conditions applies only to the Services. The Services
          may contain links to other websites not operated or controlled by
          thisorthis.ai. We are not responsible for the content, accuracy or
          opinions expressed in such websites, and such websites are not
          investigated, monitored or checked for accuracy or completeness by us.
          Please remember that when you use a link to go from the Services to
          another website, our Terms &amp; Conditions are no longer in effect.
          Your browsing and interaction on any other website, including those
          that have a link on our platform, is subject to that website’s own
          rules and policies. Such third parties may use their own cookies or
          other methods to collect information about you.
        </p>
        <h3>Automatic Newsletter Subscription</h3>
        <p>
          As part of our commitment to keeping our users informed and engaged
          with the latest developments, features, and updates, all users of
          ThisOrThis.ai will be automatically subscribed to our newsletters.
          These newsletters may include topics such as prompt engineering,
          design insights, platform updates, and other relevant content.
        </p>

        <h3>Unsubscription Option</h3>
        <p>
          We value your privacy and your right to choose the communications you
          receive. Therefore, you can unsubscribe from our newsletters at any
          time by following the unsubscribe link provided in each email or by
          adjusting your account settings on ThisOrThis.ai.
        </p>

        <h3>Third-Party Email Sharing</h3>
        <p>
          To ensure the delivery of our newsletters and to provide you with a
          high-quality user experience, we may share your email address with
          third-party service providers who assist us in sending out these
          communications. Rest assured, these third parties are bound by strict
          confidentiality agreements and data protection policies to safeguard
          your personal information.
        </p>

        <h3>Privacy and Security</h3>
        <p>
          Your privacy and the security of your personal information are of
          utmost importance to us. We implement robust security measures to
          protect your data from unauthorized access, use, or disclosure. For
          more details on how we handle your personal information, please refer
          to our Privacy Policy.
        </p>

        <p>
          By continuing to use ThisOrThis.ai, you acknowledge and agree to this
          automatic subscription and our data handling practices as described
          above.
        </p>

        <h3>Welcome Bonus Offer</h3>
        <p>
          The $1 or $2 given as part of the current welcome bonus offer is a
          limited period offer and can be terminated at any time without notice.
          The amount credited to your wallet as part of this offer can only be
          used for comparing AI models within the platform.
        </p>
        <h3>Cookies</h3>
        <p>
          thisorthis.ai uses "Cookies" to identify the areas of our website that
          you have visited. A Cookie is a small piece of data stored on your
          computer or mobile device by your web browser. We use Cookies to
          enhance the performance and functionality of our website but are
          non-essential to their use. However, without these cookies, certain
          functionality like videos may become unavailable or you would be
          required to enter your login details every time you visit the website
          as we would not be able to remember that you had logged in previously.
          Most web browsers can be set to disable the use of Cookies. However,
          if you disable Cookies, you may not be able to access functionality on
          our website correctly or at all. We never place Personally
          Identifiable Information in Cookies.
        </p>
        <h3>Changes To Our Terms &amp; Conditions</h3>
        <p>
          You acknowledge and agree that thisorthis.ai may stop (permanently or
          temporarily) providing the Service (or any features within the
          Service) to you or to users generally at thisorthis.ai’s sole
          discretion, without prior notice to you. You may stop using the
          Service at any time. You do not need to specifically inform
          thisorthis.ai when you stop using the Service. You acknowledge and
          agree that if thisorthis.ai disables access to your account, you may
          be prevented from accessing the Service, your account details or any
          files or other materials which is contained in your account.
        </p>
        <p>
          If we decide to change our Terms &amp; Conditions, we will post those
          changes on this page, and/or update the Terms &amp; Conditions
          modification date below.
        </p>
        <h3>Modifications to Our website</h3>
        <p>
          thisorthis.ai reserves the right to modify, suspend or discontinue,
          temporarily or permanently, the website or any service to which it
          connects, with or without notice and without liability to you.
        </p>
        <h3>Updates to Our website</h3>
        <p>
          thisorthis.ai may from time to time provide enhancements or
          improvements to the features/ functionality of the website, which may
          include patches, bug fixes, updates, upgrades and other modifications
          ("Updates").
        </p>
        <p>
          Updates may modify or delete certain features and/or functionalities
          of the website. You agree that thisorthis.ai has no obligation to (i)
          provide any Updates, or (ii) continue to provide or enable any
          particular features and/or functionalities of the website to you.
        </p>
        <p>
          You further agree that all Updates will be (i) deemed to constitute an
          integral part of the website, and (ii) subject to the terms and
          conditions of this Agreement.
        </p>
        <h3>Third-Party Services</h3>
        <p>
          We may display, include or make available third-party content
          (including data, information, applications and other products
          services) or provide links to third-party websites or services
          ("Third- Party Services").
        </p>
        <p>
          You acknowledge and agree that thisorthis.ai shall not be responsible
          for any Third-Party Services, including their accuracy, completeness,
          timeliness, validity, copyright compliance, legality, decency, quality
          or any other aspect thereof. thisorthis.ai does not assume and shall
          not have any liability or responsibility to you or any other person or
          entity for any Third-Party Services.
        </p>
        <p>
          Third-Party Services and links thereto are provided solely as a
          convenience to you and you access and use them entirely at your own
          risk and subject to such third parties' terms and conditions.
        </p>
        <h3>Term and Termination</h3>
        <p>
          This Agreement shall remain in effect until terminated by you or
          thisorthis.ai.
        </p>
        <p>
          thisorthis.ai may, in its sole discretion, at any time and for any or
          no reason, suspend or terminate this Agreement with or without prior
          notice.
        </p>
        <p>
          This Agreement will terminate immediately, without prior notice from
          thisorthis.ai, in the event that you fail to comply with any provision
          of this Agreement. You may also terminate this Agreement by deleting
          the website and all copies thereof from your computer.
        </p>
        <p>
          Upon termination of this Agreement, you shall cease all use of the
          website and delete all copies of the website from your computer.
        </p>
        <p>
          Termination of this Agreement will not limit any of thisorthis.ai's
          rights or remedies at law or in equity in case of breach by you
          (during the term of this Agreement) of any of your obligations under
          the present Agreement.
        </p>
        <h3>Copyright Infringement Notice</h3>
        <p>
          If you are a copyright owner or such owner’s agent and believe any
          material on our website constitutes an infringement on your copyright,
          please contact us setting forth the following information: (a) a
          physical or electronic signature of the copyright owner or a person
          authorized to act on his behalf; (b) identification of the material
          that is claimed to be infringing; (c) your contact information,
          including your address, telephone number, and an email; (d) a
          statement by you that you have a good faith belief that use of the
          material is not authorized by the copyright owners; and (e) the a
          statement that the information in the notification is accurate, and,
          under penalty of perjury you are authorized to act on behalf of the
          owner.
        </p>
        <h3>Indemnification</h3>
        <p>
          You agree to indemnify and hold thisorthis.ai and its parents,
          subsidiaries, affiliates, officers, employees, agents, partners and
          licensors (if any) harmless from any claim or demand, including
          reasonable attorneys' fees, due to or arising out of your: (a) use of
          the website; (b) violation of this Agreement or any law or regulation;
          or (c) violation of any right of a third party.
        </p>
        <h3>No Warranties</h3>
        <p>
          The website is provided to you "AS IS" and "AS AVAILABLE" and with all
          faults and defects without warranty of any kind. To the maximum extent
          permitted under applicable law, thisorthis.ai, on its own behalf and
          on behalf of its affiliates and its and their respective licensors and
          service providers, expressly disclaims all warranties, whether
          express, implied, statutory or otherwise, with respect to the website,
          including all implied warranties of merchantability, fitness for a
          particular purpose, title and non-infringement, and warranties that
          may arise out of course of dealing, course of performance, usage or
          trade practice. Without limitation to the foregoing, thisorthis.ai
          provides no warranty or undertaking, and makes no representation of
          any kind that the website will meet your requirements, achieve any
          intended results, be compatible or work with any other software,
          websites, systems or services, operate without interruption, meet any
          performance or reliability standards or be error free or that any
          errors or defects can or will be corrected.
        </p>
        <p>
          Without limiting the foregoing, neither thisorthis.ai nor any
          thisorthis.ai's provider makes any representation or warranty of any
          kind, express or implied: (i) as to the operation or availability of
          the website, or the information, content, and materials or products
          included thereon; (ii) that the website will be uninterrupted or
          error-free; (iii) as to the accuracy, reliability, or currency of any
          information or content provided through the website; or (iv) that the
          website, its servers, the content, or e-mails sent from or on behalf
          of thisorthis.ai are free of viruses, scripts, trojan horses, worms,
          malware, timebombs or other harmful components.
        </p>
        <p>
          Some jurisdictions do not allow the exclusion of or limitations on
          implied warranties or the limitations on the applicable statutory
          rights of a consumer, so some or all of the above exclusions and
          limitations may not apply to you.
        </p>
        <h3>Limitation of Liability</h3>
        <p>
          Notwithstanding any damages that you might incur, the entire liability
          of thisorthis.ai and any of its suppliers under any provision of this
          Agreement and your exclusive remedy for all of the foregoing shall be
          limited to the amount actually paid by you for the website.
        </p>
        <p>
          To the maximum extent permitted by applicable law, in no event shall
          thisorthis.ai or its suppliers be liable for any special, incidental,
          indirect, or consequential damages whatsoever (including, but not
          limited to, damages for loss of profits, for loss of data or other
          information, for business interruption, for personal injury, for loss
          of privacy arising out of or in any way related to the use of or
          inability to use the website, third-party software and/or third-party
          hardware used with the website, or otherwise in connection with any
          provision of this Agreement), even if thisorthis.ai or any supplier
          has been advised of the possibility of such damages and even if the
          remedy fails of its essential purpose.
        </p>
        <p>
          Some states/jurisdictions do not allow the exclusion or limitation of
          incidental or consequential damages, so the above limitation or
          exclusion may not apply to you.
        </p>
        <h3>Severability</h3>
        <p>
          If any provision of this Agreement is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </p>
        <p>
          This Agreement, together with the Privacy Policy and any other legal
          notices published by thisorthis.ai on the Services, shall constitute
          the entire agreement between you and thisorthis.ai concerning the
          Services. If any provision of this Agreement is deemed invalid by a
          court of competent jurisdiction, the invalidity of such provision
          shall not affect the validity of the remaining provisions of this
          Agreement, which shall remain in full force and effect. No waiver of
          any term of this Agreement shall be deemed a further or continuing
          waiver of such term or any other term, and thisorthis.ai’s failure to
          assert any right or provision under this Agreement shall not
          constitute a waiver of such right or provision. YOU AND thisorthis.ai
          AGREE THAT ANY CAUSE
        </p>
        <p>
          OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE
          WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH
          CAUSE OF ACTION IS PERMANENTLY BARRED.
        </p>
        <h3>Waiver</h3>
        <p>
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under this Agreement shall not
          effect a party's ability to exercise such right or require such
          performance at any time thereafter nor shall be the waiver of a breach
          constitute waiver of any subsequent breach.
        </p>
        <p>
          No failure to exercise, and no delay in exercising, on the part of
          either party, any right or any power under this Agreement shall
          operate as a waiver of that right or power. Nor shall any single or
          partial exercise of any right or power under this Agreement preclude
          further exercise of that or any other right granted herein. In the
          event of a conflict between this Agreement and any applicable purchase
          or other terms, the terms of this Agreement shall govern.
        </p>
        <h3>Amendments to this Agreement</h3>
        <p>
          thisorthis.ai reserves the right, at its sole discretion, to modify or
          replace this Agreement at any time. If a revision is material we will
          provide at least 30 days' notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our website after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use
          thisorthis.ai.
        </p>
        <h3>Entire Agreement</h3>
        <p>
          The Agreement constitutes the entire agreement between you and
          thisorthis.ai regarding your use of the website and supersedes all
          prior and contemporaneous written or oral agreements between you and
          thisorthis.ai.
        </p>
        <p>
          You may be subject to additional terms and conditions that apply when
          you use or purchase other thisorthis.ai's services, which
          thisorthis.ai will provide to you at the time of such use or purchase.
        </p>
        <h3>Updates to Our Terms</h3>
        <p>
          We may change our Service and policies, and we may need to make
          changes to these Terms so that they accurately reflect our Service and
          policies. Unless otherwise required by law, we will notify you (for
          example, through our Service) before we make changes to these Terms
          and give you an opportunity to review them before they go into effect.
          Then, if you continue to use the Service, you will be bound by the
          updated Terms. If you do not want to agree to these or any updated
          Terms, you can delete your account.
        </p>
        <h3>Intellectual Property</h3>
        <p>
          The website and its entire contents, features and functionality
          (including but not limited to all information, software, text,
          displays, images, video and audio, and the design, selection and
          arrangement thereof), are owned by thisorthis.ai, its licensors or
          other providers of such material and are protected by Canada and
          international copyright, trademark, patent, trade secret and other
          intellectual property or proprietary rights laws. The material may not
          be copied, modified, reproduced, downloaded or distributed in any way,
          in whole or in part, without the express prior written permission of
          thisorthis.ai, unless and except as is expressly provided in these
          Terms &amp; Conditions. Any unauthorized use of the material is
          prohibited.
        </p>
        <h3>Agreement to Arbitrate</h3>
        <p>
          This section applies to any dispute EXCEPT IT DOESN’T INCLUDE A
          DISPUTE RELATING TO CLAIMS
        </p>
        <p>
          FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR
          VALIDITY OF YOUR
        </p>
        <p>
          OR thisorthis.ai’s INTELLECTUAL PROPERTY RIGHTS. The term “dispute”
          means any dispute, action, or other controversy between you and
          thisorthis.ai concerning the Services or this agreement, whether in
          contract, warranty, tort, statute, regulation, ordinance, or any other
          legal or equitable basis. “Dispute” will be given the broadest
          possible meaning allowable under law.
        </p>
        <h3>Notice of Dispute</h3>
        <p>
          In the event of a dispute, you or thisorthis.ai must give the other a
          Notice of Dispute, which is a written statement that sets forth the
          name, address, and contact information of the party giving it, the
          facts giving rise to the dispute, and the relief requested. You must
          send any Notice of Dispute via email to: contact@thisorthis.ai.
          thisorthis.ai will send any Notice of Dispute to you by mail to your
          address if we have it, or otherwise to your email address. You and
          thisorthis.ai will attempt to resolve any dispute through informal
          negotiation within sixty (60) days from the date the Notice of Dispute
          is sent. After sixty (60) days, you or thisorthis.ai may commence
          arbitration.
        </p>
        <h3>Binding Arbitration</h3>
        <p>
          If you and thisorthis.ai don’t resolve any dispute by informal
          negotiation, any other effort to resolve the dispute will be conducted
          exclusively by binding arbitration as described in this section. You
          are giving up the right to litigate (or participate in as a party or
          class member) all disputes in court before a judge or jury. The
          dispute shall be settled by binding arbitration in accordance with the
          commercial arbitration rules of the American Arbitration Association.
          Either party may seek any interim or preliminary injunctive relief
          from any court of competent jurisdiction, as necessary to protect the
          party’s rights or property pending the completion of arbitration. Any
          and all legal, accounting, and other costs, fees, and expenses
          incurred by the prevailing party shall be borne by the non-prevailing
          party.
        </p>
        <h3>Submissions and Privacy</h3>
        <p>
          In the event that you submit or post any ideas, creative suggestions,
          designs, photographs, information, advertisements, data or proposals,
          including ideas for new or improved products, services, features,
          technologies or promotions, you expressly agree that such submissions
          will automatically be treated as non-confidential and non-proprietary
          and will become the sole property of thisorthis.ai without any
          compensation or credit to you whatsoever. thisorthis.ai and its
          affiliates shall have no obligations with respect to such submissions
          or posts and may use the ideas contained in such submissions or posts
          for any purposes in any medium in perpetuity, including, but not
          limited to, developing, manufacturing, and marketing products and
          services using such ideas.
        </p>
        <h3>Promotions</h3>
        <p>
          thisorthis.ai may, from time to time, include contests, promotions,
          sweepstakes, or other activities (“Promotions”) that require you to
          submit material or information concerning yourself. Please note that
          all Promotions may be governed by separate rules that may contain
          certain eligibility requirements, such as restrictions as to age and
          geographic location. You are responsible to read all Promotions rules
          to determine whether or not you are eligible to participate. If you
          enter any Promotion, you agree to abide by and to comply with all
          Promotions Rules.
        </p>
        <p>
          Additional terms and conditions may apply to purchases of goods or
          services on or through the Services, which terms and conditions are
          made a part of this Agreement by this reference.
        </p>
        <h3>Typographical Errors</h3>
        <p>
          In the event a product and/or service is listed at an incorrect price
          or with incorrect information due to typographical error, we shall
          have the right to refuse or cancel any orders placed for the product
          and/or service listed at the incorrect price. We shall have the right
          to refuse or cancel any such order whether or not the order has been
          confirmed and your credit card charged. If your credit card has
          already been charged for the purchase and your order is canceled, we
          shall immediately issue a credit to your credit card account or other
          payment account in the amount of the charge.
        </p>
        <h3>Miscellaneous</h3>
        <p>
          If for any reason a court of competent jurisdiction finds any
          provision or portion of these Terms &amp; Conditions to be
          unenforceable, the remainder of these Terms &amp; Conditions will
          continue in full force and effect. Any waiver of any provision of
          these Terms &amp; Conditions will be effective only if in writing and
          signed by an authorized representative of thisorthis.ai. thisorthis.ai
          will be entitled to injunctive or other equitable relief (without the
          obligations of posting any bond or surety) in the event of any breach
          or anticipatory breach by you. thisorthis.ai operates and controls the
          thisorthis.ai Service from its offices in Canada. The Service is not
          intended for distribution to or use by any person or entity in any
          jurisdiction or country where such distribution or use would be
          contrary to law or regulation. Accordingly, those persons who choose
          to access the thisorthis.ai Service from other locations do so on
          their own initiative and are solely responsible for compliance with
          local laws, if and to the extent local laws are applicable. These
          Terms &amp; Conditions (which include and incorporate the
          thisorthis.ai Privacy Policy) contains the entire understanding, and
          supersedes all prior understandings, between you and thisorthis.ai
          concerning its subject matter, and cannot be changed or modified by
          you. The section headings used in this Agreement are for convenience
          only and will not be given any legal import.
        </p>
        <h3>Disclaimer</h3>
        <p>
          thisorthis.ai is not responsible for any content, code or any other
          imprecision. thisorthis.ai does not provide warranties or guarantees.
        </p>
        <p>
          In no event shall thisorthis.ai be liable for any special, direct,
          indirect, consequential, or incidental damages or any damages
          whatsoever, whether in an action of contract, negligence or other
          tort, arising out of or in connection with the use of the Service or
          the contents of the Service. The Company reserves the right to make
          additions, deletions, or modifications to the contents on the Service
          at any time without prior notice.
        </p>
        <p>
          The thisorthis.ai Service and its contents are provided "as is" and
          "as available" without any warranty or representations of any kind,
          whether express or implied. thisorthis.ai is a distributor and not a
          publisher of the content supplied by third parties; as such,
          thisorthis.ai exercises no editorial control over such content and
          makes no warranty or representation as to the accuracy, reliability or
          currency of any information, content, service or merchandise provided
          through or accessible via the thisorthis.ai Service. Without limiting
          the foregoing, thisorthis.ai specifically disclaims all warranties and
          representations in any content transmitted on or in connection with
          the thisorthis.ai Service or on sites that may appear as links on the
          thisorthis.ai Service, or in the products provided as a part of, or
          otherwise in connection with, the thisorthis.ai Service, including
          without limitation any warranties of merchantability, fitness for a
          particular purpose or non-infringement of third party rights. No oral
          advice or written information given by thisorthis.ai or any of its
          affiliates, employees, officers, directors, agents, or the like will
          create a warranty. Price and availability information is subject to
          change without notice. Without limiting the foregoing, thisorthis.ai
          does not warrant that the thisorthis.ai Service will be uninterrupted,
          uncorrupted, timely, or error-free.
        </p>
        <h3>Contact Us</h3>
        <p>Don't hesitate to contact us if you have any questions.</p>
        <p>● Via Email: contact@thisorthis.ai</p>
      </div>
    </Box>
  );
};

export default Terms;
