import React, { useState, useRef, useCallback, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import heic2any from "heic2any";
import imageCompression from "browser-image-compression";

import {
  MODEL_DETAILS,
  ALL_MODELS,
  USER_TYPE,
  UPDATE_HISTORY,
  IS_REMEMBERED,
  SELECTED_TYPES,
} from "store/actions";
import { fetchAuthSession } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const useAskPromptHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recaptchaRef = React.useRef();

  const reduxModelDetails = useSelector(
    (state) => state.customization.modelDetails
  );
  const reduxAllModels = useSelector((state) => state.customization.allModels);
  const reduxSelModels = useSelector((state) => state.customization.selModels);
  const reduxBalance = useSelector(
    (state) => state.customization.currentBalance
  );
  const reduxIsRemember = useSelector(
    (state) => state.customization.isRemembered
  );

  const [models, setModels] = useState(reduxModelDetails);
  const [allModels, setAllModels] = useState(reduxAllModels);
  const [balance, setBalance] = useState(reduxBalance);

  const [promptText, setPromptText] = useState("");
  const [promptID, setPromptID] = useState("");
  const [selectedTypes, setSelectedTypes] = useState(reduxSelModels);
  const [sortedModels, setSortedModels] = useState(null);
  const [userType, setUserType] = useState(
    useSelector((state) => state.customization.userType)
  );
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelLoading, setModelLoading] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isLowBal, setIsLowBal] = useState(false);
  const [loadDialog, setLoadDialog] = useState(true);
  const [responseType, setResponseType] = useState("Default");
  const [advanceResponseType, setAdvanceResponseType] = useState("Precise");
  const [isRemembered, setIsRemembered] = useState(reduxIsRemember);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [imageError, setImageError] = useState("");
  const [uploadStatus, setUploadStatus] = useState({});
  const [convertingImg, setConvertingImg] = useState(false);
  const [visibleCaptcha, setVisibleCaptcha] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [warningTitle, setWarningTitle] = useState("");
  const [temperature, setTemperature] = useState(0.7);
  const [topP, setTopP] = useState(0.9);
  const fileInputRef = useRef(null);

  const maxFiles = 5;
  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes
  const ALLOWED_TYPES = [
    "image/png",
    "image/jpeg",
    "image/webp",
    "image/heic",
    "image/heif",
  ];

  useEffect(() => {
    setSelectedFiles([]);
    setDragActive(false);
    setUploading(false);
    setImageError("");
    setUploadStatus({});
    setConvertingImg(false);
    setVisibleCaptcha(false);

    const fetchUserData = async () => {
      try {
        const result = await handleFetchUserAttributes();
        const idToken = await getIdToken();

        // Get User Type and Model Details from a single GET API
        const modelResult = await axios.get(
          process.env.REACT_APP_BASE_URL + "/allModels",
          {
            params: {
              x_user_ser: result.userSerial,
              sub_id: result.sub,
            },
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const modelList = modelResult.data.modelList;
        //  console.log("Model list", modelList);
        dispatch({ type: ALL_MODELS, allModels: modelList });
        setAllModels(modelList);
        const userType = modelResult.data.userType;
        dispatch({ type: USER_TYPE, userType: userType });

        //Sort as per parent name
        const groupedModels = modelList.reduce((acc, model) => {
          const { parent } = model;
          acc[parent.S] = acc[parent.S] || {};
          acc[parent.S]["modelList"] = acc[parent.S]["modelList"] || [];
          acc[parent.S]["modelList"].push(model);
          if (!acc[parent.S]["logoUrl"]) {
            acc[parent.S]["logoUrl"] = modelResult.data.logoUrl[parent.S];
          }

          return acc;
        }, {});

        // Set the models state
        const sortedArray = Object.keys(groupedModels).sort((a, b) => {
          const a1 = groupedModels[a].modelList.length;
          const b2 = groupedModels[b].modelList.length;
          return a1 - b2;
        });
        const sortedModel = {};
        for (const key of sortedArray) {
          sortedModel[key] = groupedModels[key];
        }
        setSortedModels(sortedArray);
        setModels(sortedModel);
        console.log(sortedModel);
        dispatch({ type: MODEL_DETAILS, modelDetails: sortedModel });
        setUserType(userType);

        console.log("Choices found ", modelResult.data.selectedList);
        const x = modelResult.data.selectedList.map((id) => ({
          modelId: id,
        }));
        console.log(x);
        setSelectedTypes(x);
        dispatch({ type: SELECTED_TYPES, selModels: x });

        //Set Warning Message
        const warningObject = await modelList.find(
          (item) => item.warning?.BOOL === true
        );
        console.log("Warning found ", warningObject);

        if (warningObject) {
          setIsWarning(true);
          setWarningTitle(warningObject.warningTitle?.S || "");
          setWarningText(warningObject.warningText?.S || "");
        } else {
          setIsWarning(false);
          setWarningTitle("");
          setWarningText("");
        }

        if (modelResult.data.saveSelection) {
          setIsRemembered(true);
          dispatch({ type: IS_REMEMBERED, isRemembered: true });
        }

        setModelLoading(false);

        // console.log(groupedModels);
        // console.log("Fetched models again");
      } catch (error) {
        console.error("Error fetching user data points", error);
      }
    };

    // Call the async function when the component mounts
    if (models === null || models.length === 0) {
      // console.log("No model details present");
      fetchUserData();
    } else {
      // console.log("Model details present");
      setModelLoading(false);
    }
  }, []);

  useEffect(() => {
    if (models !== reduxModelDetails) {
      setModels(reduxModelDetails);
    }
  }, [reduxModelDetails]);

  // Update local allModels when Redux state changes
  useEffect(() => {
    if (allModels !== reduxAllModels) {
      setAllModels(reduxAllModels);
    }
  }, [reduxAllModels]);

  useEffect(() => {
    if (balance !== reduxBalance) {
      setBalance(reduxBalance);
    }
  }, [reduxBalance]);

  useEffect(() => {
    if (reduxSelModels !== selectedTypes) {
      console.log("Selected Types not in Sync");
      setSelectedTypes(reduxSelModels);
    }
  }, [reduxSelModels]);

  useEffect(() => {
    setIsRemembered(reduxIsRemember);
  }, [reduxIsRemember]);

  useEffect(() => {
    if (submitClicked) {
      // Set a timer to trigger action after 5 seconds
      const timer = setTimeout(() => {
        // Redirect to the first item in "History" list
        navigate("/view/" + promptID + "?prompt=new");
        setUploading(false);
        setLoading(false);
        setSelectedFiles([]);
        // Reset submitClicked state to trigger re-render
        setSubmitClicked(false); // Adjust the URL as per your route configuration
      }, 2500);

      // Clear the timer on component unmount or if submit button is clicked again
      return () => clearTimeout(timer);
    }
  }, [submitClicked]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setSelectedTypes((prevSelectedTypes) => {
      let newSelectedTypes;
      if (checked) {
        // Check if the value already exists in the array
        if (!prevSelectedTypes.some((type) => type.modelId === value)) {
          newSelectedTypes = [...prevSelectedTypes, { modelId: value }];
          amplitude.track("Selected Model ID " + value);
        } else {
          newSelectedTypes = prevSelectedTypes;
        }
      } else {
        newSelectedTypes = prevSelectedTypes.filter(
          (type) => type.modelId !== value
        );
        amplitude.track("Removed Model ID " + value);
      }

      // Dispatch to Redux
      dispatch({ type: SELECTED_TYPES, selModels: newSelectedTypes });

      return newSelectedTypes;
    });
  };

  const isChecked = (modelId) => {
    return selectedTypes.some((type) => type.modelId === modelId);
  };

  const handleDelete = (value) => {
    setSelectedTypes((prevSelectedTypes) => {
      const newSelectedTypes = prevSelectedTypes.filter(
        (type) => type.modelId !== value
      );

      // Dispatch to Redux
      dispatch({ type: SELECTED_TYPES, selModels: newSelectedTypes });

      return newSelectedTypes;
    });
    amplitude.track("Removed Model ID " + value);
  };

  const handleRememberChange = (event) => {
    setIsRemembered(event.target.checked);
    dispatch({ type: IS_REMEMBERED, isRemembered: event.target.checked });
    amplitude.track("Click - Remember Choices " + event.target.checked);
  };

  const handleSubmit = async () => {
    setVisibleCaptcha(true);
    setLoading(true);
    setResponseData([]);
    setIsLowBal(false);
    setLoadDialog(true);
    amplitude.track("Compare Button Clicked", { current_balance: balance });
    let isImagePresent = false;
    let imageData = null;

    //Verify Token for Recaptcha
    const token = await recaptchaRef.current.executeAsync();

    const captchaValid = await verifyToken(token);

    if (captchaValid) {
      amplitude.track("Captcha Verified");
      if (selectedFiles.length > 0) {
        imageData = await handleUpload();

        isImagePresent = true;
      }

      if (
        !isImagePresent ||
        (isImagePresent &&
          imageData &&
          imageData.length === selectedFiles.length)
      ) {
        try {
          const result = await handleFetchUserAttributes();

          const promptData = await getPromptId(
            result.userSerial,
            result.sub,
            selectedTypes
          );
          let promptId;

          if (promptData.status) {
            promptId = promptData.promptId;
            const idToken = await getIdToken();

            setPromptID(promptId);

            // console.log(promptId);
            // console.log(selectedTypes);

            const requestBody = {
              userId: result.userSerial,
              userType: userType,
              promptId: promptId,
              promptText: promptText,
              promptType: "text",
              modelDetails: selectedTypes,
              saveSelection: isRemembered,
              isImagePresent: isImagePresent,
              imageData: imageData,
              responseType:
                responseType === "Default" ? responseType : advanceResponseType,
            };

            if (advanceResponseType === "Custom") {
              requestBody.temperature = parseFloat(temperature);
              requestBody.topP = parseFloat(topP);
            }

            console.log(requestBody);

            const configHeaders = {
              headers: {
                Authorization: `Bearer ${idToken}`,
                "Content-Type": "application/json",
              },
            };
            const eventProperties = {
              promptId: promptId,
              promptType: "text",
              responseType:
                responseType === "Default" ? responseType : advanceResponseType,
            };
            amplitude.track("Comparison Successful", eventProperties);

            setSubmitClicked(true);
            const pText = promptText.slice(0, 50);
            const new_prompt = [
              {
                id: promptId,
                title: pText,
              },
            ];
            // console.log("Refreshing Menu");
            dispatch({ type: UPDATE_HISTORY, updatePromptsMenu: new_prompt });

            //Send GA event
            //  trackUserSelections(selectedTypes);

            //    console.log(trackUserSelections);

            const response = await axios.post(
              process.env.REACT_APP_BASE_URL + "/generateResponse",
              requestBody,
              configHeaders
            );

            if (response.data.success) {
              setResponseData(
                "Generating AI responses from selected models..."
              );
            }

            //If response success, load a new page and pass promptId, selectedTypes(id + publicname)
          } else if (promptData.openTopUp) {
            setIsLowBal(true);
            amplitude.track("Low Balance Dialog");
          } else {
            //Show Alert Box, Please refresh and Try Again
            // console.log("Please refresh and try again");
          }
        } catch (error) {
          console.error("Error submitting data:", error);
          amplitude.track("Error Comparing Data", {
            errorMessage: error.message,
          });
          setUploading(false);
          setLoading(false);
        }
      } else {
        console.log("Some Error Occured");
        setUploading(false);
        setLoading(false);
      }
    } else {
      console.log("Captcha verification failed");
      setUploading(false);
      setLoading(false);
      amplitude.track("Captcha Verification Failed");
    }
  };

  const handleCloseDialog = () => {
    setLoadDialog(false);
  };

  const clearSelectedTypes = () => {
    setSelectedTypes([]);
    dispatch({ type: SELECTED_TYPES, selModels: [] });
    amplitude.track("Click - Clear All models selection");
  };

  const handleResponseTypeEvent = (event) => {
    setResponseType(event.target.value);
    amplitude.track("Response Type - " + event.target.value);
  };

  const handleAdvanceResponseTypeEvent = (event, newAlignment) => {
    setAdvanceResponseType(newAlignment);
    amplitude.track("Advance Response Type - " + newAlignment);
  };

  const validateFile = useCallback((file) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      amplitude.track("Invalid Image Type - " + file.type);
      return `${file.name} is not a valid image type. Allowed types are PNG, JPEG, WEBP, HEIC and HEIF.`;
    }
    if (file.size > MAX_FILE_SIZE) {
      amplitude.track("Maximum Image Size - " + file.size);
      return `${file.name} is too large. Maximum single file size allowed is 20 MB.`;
    }
    return null;
  }, []);

  const handleFileSelect = useCallback((event) => {
    const files = Array.from(event.target.files);
    amplitude.track("Images Added", { images: files.length });
    setImageError("");
    addFiles(files);
  }, []);

  const addFiles = useCallback(
    async (files) => {
      const newValidFiles = [];
      const errors = [];

      const compressImage = async (file, maxSizeMB = 5) => {
        const options = {
          maxSizeMB: maxSizeMB,
          maxWidthOrHeight: 800,
          useWebWorker: true,
          initialQuality: 0.7,
        };

        try {
          let compressedFile = await imageCompression(file, options);

          while (
            compressedFile.size > maxSizeMB * 1024 * 1024 &&
            options.initialQuality > 0.1
          ) {
            options.initialQuality -= 0.1;
            compressedFile = await imageCompression(file, options);
          }

          console.log(
            `Compressed ${file.name}: ${file.size / 1024 / 1024}MB -> ${compressedFile.size / 1024 / 1024}MB`
          );
          return compressedFile;
        } catch (error) {
          console.error("Error compressing image:", error);
          return file;
        }
      };

      const processFile = async (file) => {
        setConvertingImg(true);
        const validationError = validateFile(file);
        if (validationError) {
          errors.push(validationError);
        } else {
          let processedFile = file;

          if (file.type === "image/heic" || file.type === "image/heif") {
            try {
              const jpegBlob = await heic2any({
                blob: file,
                toType: "image/jpeg",
                quality: 0.9,
              });
              processedFile = new File(
                [jpegBlob],
                file.name.replace(/\.(heic|heif)$/i, ".jpg"),
                {
                  type: "image/jpeg",
                  lastModified: file.lastModified,
                }
              );
            } catch (err) {
              errors.push(`Error converting ${file.name}. Please try again`);
              setConvertingImg(false);
              return;
            }
          }

          // Compress the image

          const compressedFile = await compressImage(processedFile);

          newValidFiles.push(compressedFile);
          setConvertingImg(false);
        }
      };

      // Process all files concurrently
      await Promise.all(files.map(processFile));

      setSelectedFiles((prevFiles) => {
        const allFiles = [...prevFiles, ...newValidFiles];
        if (allFiles.length > maxFiles) {
          errors.push(
            `Maximum ${maxFiles} files allowed. Some files were not added.`
          );
          amplitude.track("Error adding images - Max 5 files Allowed");
          return allFiles.slice(0, maxFiles);
        } else {
          return allFiles;
        }
      });

      if (errors.length > 0) {
        setImageError(errors.join("\n"));
      }
    },
    [maxFiles, validateFile]
  );

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        addFiles(Array.from(e.dataTransfer.files));
      }
      amplitude.track("Images Dropped");
    },
    [addFiles]
  );

  const removeFile = useCallback((index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setImageError(""); // Clear error when removing a file
  }, []);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const verifyToken = async (token) => {
    try {
      const idToken = await getIdToken();
      const configHeaders = {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/verifyCaptcha",
        {
          response: token,
          path: "main",
        },
        configHeaders
      );

      if (response.data.success) {
        setVisibleCaptcha(false);
        console.log("Captcha Verified");
        return true;
      } else {
        setVisibleCaptcha(false);
        throw new Error("ReCAPTCHA error");
      }
    } catch (error) {
      setVisibleCaptcha(false);
      console.error("Error verifying ReCAPTCHA:", error);
      throw error;
    }
  };

  const handleUpload = useCallback(async () => {
    setUploading(true);
    setImageError("");
    console.log("Uploading Images");

    const uploadFile = async (file, index, retries = 0) => {
      setUploadStatus((prev) => ({ ...prev, [index]: "uploading" }));
      const { userSerial } = await handleFetchUserAttributes();
      //  console.log("User Serial:", userSerial);

      const formData = new FormData();
      formData.append(`image${index}`, file);
      formData.append("x_user_ser", userSerial);

      try {
        const idToken = await getIdToken();

        const configHeaders = {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "multipart/form-data",
          },
        };

        console.log(`Sending image ${index}`);
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "/user/uploadImages",
          formData,
          configHeaders
        );

        if (response.data.items) {
          setUploadStatus((prev) => ({ ...prev, [index]: "success" }));
          return { index, data: response.data.items[0] };
        } else {
          throw new Error("Upload failed");
        }
      } catch (err) {
        console.error(`Error uploading image ${index}:`, err);

        if (retries < 4) {
          // 5 attempts total (initial + 4 retries)
          const delay = Math.min(1000 * Math.pow(2, retries), 3000); // 1s, 2s, 3s, 3s, 3s
          console.log(`Retrying upload for image ${index} in ${delay}ms...`);
          await new Promise((resolve) => setTimeout(resolve, delay));
          return uploadFile(file, index, retries + 1);
        }

        setUploadStatus((prev) => ({ ...prev, [index]: "error" }));
        return { index, error: err };
      }
    };

    try {
      const uploadPromises = selectedFiles.map((file, index) =>
        uploadFile(file, index)
      );
      const results = await Promise.all(uploadPromises);
      amplitude.track("Image(s) Uploaded");

      const successfulUploads = results.filter((result) => !result.error);
      const failedUploads = results.filter((result) => result.error);

      if (failedUploads.length > 0) {
        setImageError(
          `Error uploading ${failedUploads.length} image(s). Please try again.`
        );
      }

      const uploadedItems = successfulUploads.map((result) => ({
        ...result.data,
      }));

      return uploadedItems;
    } catch (err) {
      setImageError("Error uploading images. Please try again.");
      setUploading(false);
      setLoading(false);
      setLoadDialog(false);
      console.error(err);
      return null;
    }
  }, [selectedFiles]);

  const handleTemperatureChange = (newValue) => {
    setTemperature(newValue);
    console.log("New temperature:", newValue);
  };

  const handleTopPChange = (newValue) => {
    setTopP(newValue);
    console.log("New topP:", newValue);
  };

  return {
    models,
    selectedTypes,
    setSelectedTypes,
    handleCheckboxChange,
    promptText,
    setPromptText,
    handleSubmit,
    handleDelete,
    isLowBal,
    loadDialog,
    handleCloseDialog,
    loading, //loading when user lclick on submit
    modelLoading, //initial load of models data
    responseType,
    handleResponseTypeEvent,
    advanceResponseType,
    handleAdvanceResponseTypeEvent,
    sortedModels,
    allModels,
    setIsRemembered,
    isRemembered,
    isChecked,
    clearSelectedTypes,
    handleRememberChange,
    selectedFiles,
    dragActive,
    uploading,
    imageError,
    fileInputRef,
    handleFileSelect,
    handleDrag,
    handleDrop,
    removeFile,
    uploadStatus,
    convertingImg,
    recaptchaRef,
    visibleCaptcha,
    isWarning,
    warningText,
    warningTitle,
    temperature,
    handleTemperatureChange,
    topP,
    handleTopPChange,
  };
};

async function getIdToken() {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    return idToken;
  } catch (error) {
    console.log("Error getting ID token:", error);
    return null;
  }
}

async function handleFetchUserAttributes() {
  try {
    const userAttributes = await fetchUserAttributes();
    //console.log(userAttributes);
    return {
      userSerial: userAttributes["custom:user_serial"],
      sub: userAttributes.sub,
    };
  } catch (error) {
    console.log(error);
  }
}

async function getPromptId(x_user_ser, sub_id, models) {
  try {
    const idToken = await getIdToken();

    const response = await axios.get(
      process.env.REACT_APP_BASE_URL + "/promptID",
      {
        params: {
          x_user_ser: x_user_ser,
          sub_id: sub_id,
          models: models,
        },
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    // console.log(response.data);

    if (response.data.proceed) {
      return {
        status: true,
        promptId: response.data.promptId,
      };
    } else if (!response.data.proceed) {
      return {
        status: false,
        openTopUp: true,
        message: response.data.message,
      };
    } else if (response.status === 403) {
      return {
        status: false,
        openTopUp: false,
        message: response.data.message,
      };
    }
  } catch (error) {
    console.error("Error fetching users:", error);
  }
}

export default useAskPromptHook;
