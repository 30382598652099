import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import axios from "axios";

import PaymentDialog from "./PaymentDialog";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import * as amplitude from "@amplitude/analytics-browser";

const Topup = ({ isOpen, isLow }) => {
  const [loadDialog, setLoadDialog] = useState(false);

  useEffect(() => {
    changeState();
  }, [isOpen]);

  const changeState = async () => {
    setLoadDialog(isOpen);
  };

  const handleClick = async (e) => {
    amplitude.track("Topup Dialog Opened");
    setLoadDialog(true);
  };

  const handleCloseDialog = () => {
    setLoadDialog(false);
  };

  return (
    <>
      <IconButton className="p-0 ml-4" onClick={handleClick}>
        <Avatar
          alt="add_balance"
          sx={{
            padding: ".2em",
            width: 32,
            height: 32,
            backgroundColor: "#336FD6",
            ".MuiAvatar-img": {
              objectFit: "contain",
              width: "85%",
              height: "85%",
            },
          }}
          src="/images/add_balance_icon.svg"
        />
      </IconButton>

      {loadDialog ? (
        <PaymentDialog open={loadDialog} onClose={handleCloseDialog} />
      ) : (
        <></>
      )}
    </>
  );
};

export default Topup;
