import React, { useEffect, useState } from "react";
import css from "./DashboardHomePage.module.css";
import {
  AddPhotoAlternate as AddPhotoIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Link,
  Alert,
  AlertTitle,
  Stack,
  Chip,
  Typography,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
  toggleButtonGroupClasses,
  Badge,
  Collapse,
  useMediaQuery,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Avatar from "@mui/material/Avatar";
import useAskPromptHook from "./useAskPromptHook";
import PaymentDialog from "views/payment/PaymentDialog";
import Skeleton from "@mui/material/Skeleton";
import * as amplitude from "@amplitude/analytics-browser";
import ReCAPTCHA from "react-google-recaptcha";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import styled from "@emotion/styled";
import CustomSlider from "components/CustomSlider";
//import AIModelSelector from "./ModelSelection";

const AskPromptComponents = () => {
  const mob700 = useMediaQuery("(max-width:700px)");
  const mob492 = useMediaQuery("(max-width:492px)");
  const mob400 = useMediaQuery("(max-width:400px)");
  const [limitError, setLimitError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const charLimit = 10000;

  const {
    models,
    allModels,
    selectedTypes,
    handleCheckboxChange,
    promptText,
    setPromptText,
    handleSubmit,
    handleDelete,
    isLowBal,
    loadDialog,
    handleCloseDialog,
    modelLoading,
    loading,
    setSelectedTypes,
    responseType,
    handleResponseTypeEvent,
    advanceResponseType,
    handleAdvanceResponseTypeEvent,
    sortedModels,
    setIsRemembered,
    isRemembered,
    isChecked,
    clearSelectedTypes,
    handleRememberChange,
    selectedFiles,
    dragActive,
    uploading,
    imageError,
    fileInputRef,
    handleFileSelect,
    handleDrag,
    handleDrop,
    removeFile,
    uploadStatus,
    convertingImg,
    recaptchaRef,
    visibleCaptcha,
    isWarning,
    warningText,
    warningTitle,
    temperature,
    handleTemperatureChange,
    topP,
    handleTopPChange,
  } = useAskPromptHook();

  //console.log("All Models are: ", allModels);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setSelectedTypes((prevSelectedTypes) =>
        prevSelectedTypes.filter((item) => {
          // Iterate through all parent models
          for (const parent of Object.keys(models)) {
            // Find the model in the current parent's modelList
            const model = models[parent].modelList.find(
              (m) => m.modelId.N === item.modelId
            );

            if (model) {
              // If model is found, return true if it has vision, false otherwise
              return model.hasVision?.BOOL === true;
            }
          }

          // If model is not found in any parent, log a warning and remove it
          console.warn(
            `Model with ID ${item.modelId} not found in any parent model list`
          );
          return false;
        })
      );
    }
  }, [selectedFiles, models]);

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    background: "#fff",

    [`& .${toggleButtonGroupClasses.grouped}`]: {
      padding: mob700 ? "0.5em 1em" : "0.5em 2em",
      color: "#BDBDBD",
      fontWeight: "500",
      borderColor: "#e2e2e2",
      fontSize: mob492 ? "8px" : mob700 ? "12px" : "16px",
      "&::hover": {
        opacity: 1,
        backgroundColor: "#336FD6",
      },

      [`&.${toggleButtonGroupClasses.disabled}`]: {
        color: "#BDBDBD",
      },
      [`&.${toggleButtonGroupClasses.selected}`]: {
        backgroundColor: "#336FD6",
        color: "#fff",
        fontWeight: "700",
        "&:hover": {
          backgroundColor: "#336FD6",
        },
      },
    },
  }));

  const exampleText = {
    Precise: {
      heading: "Provide accurate and factual responses with minimal variation.",
      example: [
        "What is the capital of France?",
        `Who wrote "To Kill a Mockingbird"?`,
      ],
    },
    Informative: {
      heading:
        "Deliver informative and educational content with clarity and richness of vocabulary.",
      example: [
        "Explain the process of photosynthesis in plants.",
        `What are the main functions of the human respiratory system?`,
      ],
    },
    Balanced: {
      heading:
        "Strike a balance between creativity and predictability, offering consistent yet varied responses.",
      example: [
        "Write a short story about a mysterious island.",
        `Describe your favorite childhood memory.`,
      ],
    },
    Creative: {
      heading:
        "Explore imaginative scenarios and create unique narratives with diverse but coherent elements.",
      example: [
        "Describe a world where humans have the ability to manipulate time.",
        `Create a character who can communicate with animals.`,
      ],
    },
    Unique: {
      heading:
        "Generate unconventional and abstract ideas with controlled unpredictability.",
      example: [
        "Compose a poem about the sound of silence.",
        `Imagine a world without colors.`,
      ],
    },
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      amplitude.track("Hide AI Models");
    } else {
      amplitude.track("Show all AI Models");
    }
  };

  const handleChange = (event) => {
    setPromptText(event.target.value);
    const inputText = event.target.value;
    if (inputText.length < charLimit) {
      setLimitError(false); // Reset error state when text is within limit
    } else {
      setLimitError(true); // Set error state when text exceeds limit
      amplitude.track("Character Limit Reached");
    }
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(133deg, #006DF2 20%, #02688C 100%)",
        }}
        className="p-1 rounded-3xl"
      >
        <div className="bg-[#EEF2F6] w-full h-max rounded-[20px] flex flex-col items-center py-10">
          {modelLoading ? (
            <div className="w-[90%] flex flex-col items-center">
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem", width: "60%" }}
              />
              <Skeleton
                className="rounded-[2em] my-8 w-full"
                variant="rectangular"
                height={60}
              />
              <div className="w-full flex justify-between">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "2rem", width: "60%" }}
                />
                <Skeleton variant="rounded" width={100} height={20} />
              </div>
              <Grid container spacing={4} className="mt-8">
                {[1, 2, 3, 4, 5, 6].map((elm) => (
                  <Grid item xs={4} md={3} key={elm}>
                    <div className="flex flex-col items-end">
                      <Skeleton
                        className="w-full"
                        variant="text"
                        sx={{ fontSize: "2rem" }}
                      />
                      <Skeleton
                        className="rounded-[2em] mt-4 w-[80%] "
                        variant="rectangular"
                        height={30}
                      />
                      <Skeleton
                        className="rounded-[2em] mt-4 w-[80%]  "
                        variant="rectangular"
                        height={30}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          ) : (
            <>
              <h2 className={css.gradient_text}>
                Compare AI Responses Side-by-Side
              </h2>
              <div className="max-[492px]:flex flex-col w-[90%] sm:w-[95%]">
                <Paper
                  className={css.compareForm}
                  sx={{
                    px: mob492 ? 2 : 3,
                    py: mob492 ? 1 : 2,
                    border: "1px solid",
                    borderColor: dragActive ? "primary.main" : "grey.300",
                    borderStyle: dragActive ? "dashed" : "solid",
                    transition: "all 0.3s ease",
                  }}
                >
                  <TextField
                    sx={{
                      ".MuiInputBase-input::placeholder": {
                        color: "#004174",
                        opacity: 0.8,
                        fontWeight: 600,
                        fontSize: "16px",
                      },
                    }}
                    multiline
                    maxRows={12}
                    id="srch-articles"
                    placeholder="Ask me anything..."
                    fullWidth
                    variant="standard"
                    error={limitError}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                    helperText={
                      limitError
                        ? "Maximum character limit of " +
                          charLimit +
                          " reached."
                        : ""
                    }
                    inputProps={{ maxLength: charLimit }}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: mob492 ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => fileInputRef.current.click()}
                            className="mr-0"
                          >
                            <AddPhotoIcon sx={{ color: "#336FD6" }} />
                          </IconButton>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <Tooltip title="Upload Image(s)">
                            <IconButton
                              onClick={() => fileInputRef.current.click()}
                              className="mr-2"
                              disabled={loading}
                            >
                              <AddPhotoIcon
                                sx={{ color: !loading ? "#336FD6" : "#D3D3D3" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Button
                            disableElevation
                            sx={{
                              background:
                                "linear-gradient(to right, #022735fa, #006DF2 )",
                              borderRadius: "2em",
                              padding: "0.5rem 1rem",
                              ".MuiButton-root": {
                                borderRadius: "50%",
                              },
                              "&.Mui-disabled": {
                                background: "#D3D3D3",
                                color: "#fff",
                                cursor: "not-allowed",
                              },
                            }}
                            variant="contained"
                            endIcon={
                              loading ? (
                                <CircularProgress
                                  className="text-white"
                                  size="15px"
                                />
                              ) : (
                                <AutoAwesomeIcon />
                              )
                            }
                            onClick={handleSubmit}
                            disabled={
                              selectedTypes.length < 2 ||
                              promptText.trim().split(/\s+/).length < 3 ||
                              promptText.trim() === "" ||
                              loading
                            }
                          >
                            Compare
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    // multiline
                    required
                    value={promptText}
                    onChange={(e) => handleChange(e)}
                    // rows={1}
                  />
                </Paper>
                {mob492 ? (
                  <Button
                    disableElevation
                    sx={{
                      background:
                        "linear-gradient(to right, #022735fa, #006DF2 )",
                      borderRadius: "2em",
                      padding: "0.5rem 1rem",
                      marginTop: "1em",
                      ".MuiButton-root": {
                        borderRadius: "50%",
                      },
                      "&.Mui-disabled": {
                        background: "#D3D3D3",
                        color: "#fff",
                        cursor: "not-allowed",
                      },
                    }}
                    variant="contained"
                    endIcon={
                      loading ? (
                        <CircularProgress className="text-white" size="15px" />
                      ) : (
                        <AutoAwesomeIcon />
                      )
                    }
                    onClick={handleSubmit}
                    disabled={
                      selectedTypes.length < 2 ||
                      promptText.trim().split(/\s+/).length < 3 ||
                      promptText.trim() === "" ||
                      loading
                    }
                  >
                    Compare
                  </Button>
                ) : (
                  <></>
                )}
              </div>
              <input
                type="file"
                multiple
                accept="image/png,image/jpeg,image/webp,image/heic,image/heif"
                onChange={handleFileSelect}
                ref={fileInputRef}
                style={{ display: "none" }}
              />

              <div className="w-[90%] sm:w-[93%] mt-2">
                <div className="w-full">
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: 2,
                      minHeight: "38px",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      flexWrap="wrap"
                      sx={{
                        gap: 1,
                        flex: "1 1 auto",
                        minWidth: 0, // Allow shrinking below content size
                      }}
                    >
                      {selectedTypes.map((id) => {
                        const matchingModels = Object.values(allModels).filter(
                          (obj) => obj.modelId.N === id.modelId
                        );
                        if (matchingModels.length > 0) {
                          return (
                            <Chip
                              key={id.modelId}
                              label={matchingModels[0].publicName.S}
                              onDelete={() => handleDelete(id.modelId)}
                              sx={{ margin: "2px 0" }}
                            />
                          );
                        }
                        return null;
                      })}
                    </Stack>
                    {selectedTypes.length > 0 && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={isRemembered}
                            onChange={handleRememberChange}
                            sx={{
                              "&.Mui-checked": {
                                color: "#336FD6",
                                borderRadius: "4px",
                              },
                              "&:hover": {
                                backgroundColor: "#336FD61A",
                              },
                              transition: "background-color 0.3s, color 0.3s",
                            }}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            Remember choices
                          </Typography>
                        }
                        sx={{
                          margin: 0,
                          fontSize: "0.8rem",
                          flexShrink: 0,
                          marginLeft: "auto", // Push to the right
                        }}
                      />
                    )}
                  </Box>
                  <Grid
                    container
                    spacing={1}
                    sx={{ mt: 2, width: "auto", maxWidth: 500, mb: 2 }}
                  >
                    {selectedFiles && (
                      <>
                        {selectedFiles.map((file, index) => (
                          <Grid
                            item
                            key={index}
                            xs={4}
                            sm={3}
                            md={2}
                            sx={{ aspectRatio: "1 / 1" }}
                          >
                            <Box
                              sx={{
                                position: "relative",
                                maxWidth: "100px",
                                height: "100%",
                                overflow: "hidden",
                                boxShadow: 1,
                                borderRadius: "0.5em",
                              }}
                            >
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`Preview ${index}`}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  filter: uploading
                                    ? "brightness(50%)"
                                    : "none",
                                }}
                              />
                              {uploading &&
                                uploadStatus[index] === "uploading" && (
                                  <CircularProgress
                                    size={40}
                                    sx={{
                                      position: "absolute",
                                      color: "white",
                                      top: "20%",
                                      right: "30%",
                                      left: "20%",
                                      transform: "translate(-50%, -50%)",
                                      zIndex: 1,
                                    }}
                                  />
                                )}
                              {uploadStatus[index] === "success" && (
                                <CheckCircleIcon
                                  sx={{
                                    position: "absolute",
                                    color: "green",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    zIndex: 1,
                                    fontSize: 40,
                                  }}
                                />
                              )}
                              <IconButton
                                size="small"
                                onClick={() => removeFile(index)}
                                sx={{
                                  position: "absolute",
                                  top: 1,
                                  right: 1,
                                  bgcolor: "background.paper",
                                  "&:hover": {
                                    bgcolor: "background.paper",
                                  },
                                  visibility: uploading ? "hidden" : "visible",
                                }}
                              >
                                <CloseIcon
                                  sx={{
                                    "&:hover": {
                                      color: "#ff1744",
                                      bgcolor: "background.paper",
                                    },
                                    fontSize: "0.75rem",
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Grid>
                        ))}
                      </>
                    )}
                    {convertingImg && (
                      <CircularProgress
                        size={40}
                        thickness={4}
                        className="ml-4 mt-4"
                      />
                    )}
                  </Grid>

                  {imageError && (
                    <Typography color="error" sx={{ mt: 2 }}>
                      {imageError.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  )}
                  <div className="flex flex-wrap justify-between items-center gap-2 mt-2">
                    <div className="flex items-center gap-2">
                      <h4 className="text-[#030C15] text-xl max-[700px]:text-lg font-semibold">
                        Select AI Models
                      </h4>
                      <Tooltip title={isOpen ? "" : "Show All"}>
                        <IconButton
                          aria-expanded={isOpen}
                          aria-label="show more"
                          onClick={handleClick}
                          sx={{
                            backgroundColor: "transparent",
                            borderRadius: "4px",
                            "&:hover": {
                              backgroundColor: "rgba(25, 118, 210, 0.04)",
                            },
                            padding: "6px",
                            position: "relative",
                            "&::after": {
                              content: '""',
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              borderRadius: "4px",
                              border: "2px solid transparent",
                              transition: "border-color 0.2s ease-in-out",
                              animation: "borderAnimation 2s ease-in-out", // Added animation
                            },
                            "&:hover::after": {
                              borderColor: "#1976d2",
                            },
                            "@keyframes borderAnimation": {
                              // Define the animation
                              "0%": {
                                border: "2px solid transparent",
                              },
                              "50%": {
                                border: "2px solid #1976d2",
                              },
                              "100%": {
                                border: "2px solid transparent",
                              },
                            },
                          }}
                        >
                          {isOpen ? (
                            <ExpandLessIcon
                              sx={{
                                color: "#1976d2",
                                fontSize: "30px",
                                transition: "transform 0.2s ease-in-out",
                                transform: "translateY(-1px)", // Subtle upward shift
                              }}
                            />
                          ) : (
                            <ExpandMoreIcon
                              sx={{
                                color: "#1976d2",
                                fontSize: "30px",
                                transition: "transform 0.2s ease-in-out",
                                transform: "translateY(1px)", // Subtle downward shift
                              }}
                            />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>

                    <Button
                      onClick={() => clearSelectedTypes()}
                      sx={{
                        color: "#7C7C7C",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        minWidth: "auto",
                      }}
                      startIcon={<DeleteOutlineIcon />}
                      disabled={selectedTypes.length === 0}
                    >
                      Clear
                    </Button>
                  </div>
                  {isWarning && (
                    <Alert
                      severity="warning"
                      sx={{
                        "& .MuiAlert-message": {
                          width: "100%",
                        },
                        backgroundColor: "#FFF8E1",
                        color: "#663C00",
                        marginBottom: 1,
                      }}
                    >
                      <AlertTitle sx={{ fontWeight: 600 }}>
                        {warningTitle}
                      </AlertTitle>
                      {warningText}
                    </Alert>
                  )}
                </div>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: mob700 ? "12px" : "14px",
                    color: "#030C15",
                    fontWeight: "400",
                  }}
                >
                  Min: 2 | Max: 6
                </Typography>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  {" "}
                  <div className="mt-8">
                    <Grid container spacing={4}>
                      {Object.keys(models).map((parent, parentIndex) => (
                        <Grid item xs={mob492 ? 6 : 4} md={3} key={parentIndex}>
                          <Box className=" flex items-center">
                            <Box className="w-8 h-8 rounded-full flex justify-center items-center bg-white shadow-md">
                              <Avatar
                                sx={{
                                  width: mob400 ? 35 : mob700 ? 40 : 50,
                                  height: mob400 ? 35 : mob700 ? 40 : 50,
                                }}
                                className="bg-transparent"
                                alt={parent}
                                src={models[parent].logoUrl}
                              />
                            </Box>
                            <Typography
                              variant="h4"
                              sx={{
                                fontSize: mob700 ? "14px" : "16px",
                                color: "#030C15",
                                fontWeight: "600",
                                marginLeft: mob400 ? "10px" : "15px",
                              }}
                            >
                              {parent}
                            </Typography>
                          </Box>

                          <FormGroup className="ml-14 max-[400px]:ml-10">
                            {models[parent].modelList.map(
                              (model, modelIndex) => {
                                return (
                                  <FormControlLabel
                                    key={modelIndex}
                                    className="w-fit "
                                    control={
                                      <>
                                        {
                                          <Badge
                                            key={modelIndex}
                                            badgeContent={
                                              model.tierId.N === "0"
                                                ? "Free"
                                                : null
                                            }
                                            color="primary"
                                            sx={{
                                              ...(model.tierId.N === "0" && {
                                                "& .MuiBadge-badge": {
                                                  backgroundColor: "#b3e5fc", // Adjust background color as needed
                                                  color: "#000",
                                                  position: "absolute",
                                                  padding: "0 2.5px",
                                                  borderRadius: "5px",
                                                  right: mob400
                                                    ? -5
                                                    : mob700
                                                      ? -10
                                                      : -15,
                                                  fontSize: mob400
                                                    ? "0.5rem"
                                                    : mob700
                                                      ? "0.6rem"
                                                      : "0.6rem",
                                                },
                                              }),
                                            }}
                                          >
                                            <Checkbox
                                              className="p-0 ml-4"
                                              id={model.modelId.N}
                                              value={model.modelId.N}
                                              checked={isChecked(
                                                model.modelId.N
                                              )}
                                              onChange={handleCheckboxChange}
                                              disabled={
                                                (selectedTypes.length > 5 &&
                                                  !isChecked(
                                                    model.modelId.N
                                                  )) ||
                                                (selectedFiles.length > 0 &&
                                                  !model.hasVision?.BOOL)
                                              }
                                              icon={<div />} // Hidden icon
                                              checkedIcon={<div />}
                                            />
                                          </Badge>
                                        }
                                      </>
                                    }
                                    label={
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          fontSize: mob400
                                            ? "0.5rem"
                                            : mob700
                                              ? "0.7rem"
                                              : "0.9rem",
                                          color: selectedTypes.some(
                                            (obj) =>
                                              obj.modelId &&
                                              obj.modelId === model.modelId.N
                                          )
                                            ? "#fff"
                                            : (selectedTypes.length > 5 &&
                                                  !isChecked(
                                                    model.modelId.N
                                                  )) ||
                                                (selectedFiles.length > 0 &&
                                                  !model.hasVision?.BOOL)
                                              ? "#c9c9c9"
                                              : "#030C15",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {model.publicName.S}
                                      </Typography>
                                    }
                                    sx={{
                                      color: model.hasVision?.BOOL
                                        ? "black"
                                        : "gray",
                                      flexDirection: "row-reverse",
                                      marginTop: "10px",
                                      marginRight: 0,
                                      padding: mob700
                                        ? "5px 10px"
                                        : "10px 20px",
                                      borderRadius: mob400 ? "10px" : "30px",
                                      border: "1px solid #B5DAFF",
                                      backgroundColor: selectedTypes.some(
                                        (obj) =>
                                          obj.modelId &&
                                          obj.modelId === model.modelId.N
                                      )
                                        ? "#336FD6"
                                        : "#EEF2F6",
                                      "&:hover": {
                                        backgroundColor: selectedTypes.some(
                                          (obj) =>
                                            obj.modelId &&
                                            obj.modelId === model.modelId.N
                                        )
                                          ? "#336FD6"
                                          : "#B5DAFF",
                                        transition: "0.1s ease",
                                      },
                                      "&.Mui-disabled": {
                                        backgroundColor: "#ebebeb", // Change background color when disabled
                                        color: "#c9c9c9", // Change text color when disabled
                                        "& .MuiCheckbox-root": {
                                          color: "ebebeb", // Change checkbox icon color when disabled
                                        },
                                      },
                                    }}
                                  />
                                );
                              }
                            )}
                          </FormGroup>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                </Collapse>

                <Divider
                  className="my-8 border-[#B8DBFF] border-b-2 opacity-30"
                  component="div"
                />
                <div>
                  <h4 className="text-[#030C15] text-xl font-semibold max-[700px]:text-lg">
                    Choose Response Type
                  </h4>
                  <FormControl>
                    <RadioGroup
                      row
                      className="mt-4"
                      aria-labelledby="Response-type-lable"
                      name="Response-type"
                      value={responseType}
                      onChange={handleResponseTypeEvent}
                    >
                      <FormControlLabel
                        value="Default"
                        className="text-lg max-[700px]:text-sm font-semibold"
                        sx={{
                          ".MuiTypography-root": {
                            fontSize: "16px",
                            fontWeight:
                              responseType === "Default" ? "600" : "400",
                          },
                        }}
                        control={
                          <Radio
                            size="medium"
                            sx={{
                              color: "#B7B7B7",
                              "&.Mui-checked": {
                                color: "#1975D2",
                              },
                            }}
                          />
                        }
                        label="Default"
                      />
                      <FormControlLabel
                        value="Advance"
                        className="text-lg max-[700px]:text-sm font-semibold"
                        sx={{
                          ".MuiTypography-root": {
                            fontSize: "16px",
                            fontWeight:
                              responseType === "Advance" ? "600" : "400",
                          },
                        }}
                        control={
                          <Radio
                            size="medium"
                            sx={{
                              color: "#B7B7B7",
                              "&.Mui-checked": {
                                color: "#1975D2",
                              },
                            }}
                          />
                        }
                        label="Advanced"
                      />
                    </RadioGroup>
                  </FormControl>
                  {responseType === "Advance" && (
                    <div className="mt-4 w-full text-left max-w-[95%] md:max-w-[80%] lg:max-w-[60%] ">
                      <Box
                        sx={{
                          overflowX: { xs: "auto", md: "hidden" }, // Scroll on mobile, hidden on desktop
                          "& .MuiToggleButtonGroup-root": {
                            display: "flex",
                            flexWrap: { xs: "nowrap", md: "wrap" },
                            minWidth: { xs: "max-content", md: "auto" },
                            width: "100%",
                          },
                          "& .MuiToggleButton-root": {
                            flex: { xs: "0 0 auto", md: "1" },
                            minWidth: { xs: "120px", md: "auto" },
                            px: { xs: 2, md: 3 },
                            whiteSpace: "nowrap",
                          },
                        }}
                      >
                        <StyledToggleButtonGroup
                          value={
                            responseType === "Advance" && advanceResponseType
                          }
                          exclusive
                          disabled={responseType === "Default"}
                          onChange={handleAdvanceResponseTypeEvent}
                          aria-label="Advance Response types"
                        >
                          <ToggleButton value="Precise" aria-label="Precise">
                            <Typography
                              sx={{
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                whiteSpace: "nowrap",
                              }}
                            >
                              Precise
                            </Typography>
                          </ToggleButton>
                          <ToggleButton
                            value="Informative"
                            aria-label="Informative"
                          >
                            <Typography
                              sx={{
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                whiteSpace: "nowrap",
                              }}
                            >
                              Informative
                            </Typography>
                          </ToggleButton>
                          <ToggleButton value="Balanced" aria-label="Balanced">
                            <Typography
                              sx={{
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                whiteSpace: "nowrap",
                              }}
                            >
                              Balanced
                            </Typography>
                          </ToggleButton>
                          <ToggleButton value="Creative" aria-label="Creative">
                            <Typography
                              sx={{
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                whiteSpace: "nowrap",
                              }}
                            >
                              Creative
                            </Typography>
                          </ToggleButton>
                          <ToggleButton value="Unique" aria-label="Unique">
                            <Typography
                              sx={{
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                whiteSpace: "nowrap",
                              }}
                            >
                              Unique
                            </Typography>
                          </ToggleButton>
                          <ToggleButton value="Custom" aria-label="Custom">
                            <Typography
                              sx={{
                                fontSize: { xs: "0.75rem", sm: "1rem" },
                                whiteSpace: "nowrap",
                              }}
                            >
                              Custom
                            </Typography>
                          </ToggleButton>
                        </StyledToggleButtonGroup>
                      </Box>
                      {advanceResponseType !== "Custom" &&
                      responseType === "Advance" ? (
                        <div className="bg-white w-full mt-4 p-6 rounded-xl">
                          <h5 className="sm:text-lg text-sm font-medium text-[#030C15]">
                            {exampleText[advanceResponseType].heading}
                          </h5>
                          <h6 className="sm:text-base text-xs font-semibold text-[#030C15] mt-4 mb-4">
                            Examples
                          </h6>
                          {exampleText[advanceResponseType].example.map(
                            (elm, i) => (
                              <p
                                key={`rowiuefcwoelkkj${i}`}
                                className="sm:text-base text-xs font-normal text-[#030C15] mt-2"
                              >
                                {elm}
                              </p>
                            )
                          )}
                        </div>
                      ) : advanceResponseType === "Custom" &&
                        responseType === "Advance" ? (
                        <CustomSlider
                          temperature={temperature}
                          topP={topP}
                          onTemperatureChange={handleTemperatureChange}
                          onTopPChange={handleTopPChange}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {isLowBal && (
                <>
                  <PaymentDialog
                    open={loadDialog}
                    onClose={handleCloseDialog}
                  />
                  <Snackbar
                    open={loadDialog}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={handleCloseDialog}
                    message="Insufficient Balance. Please add funds to continue."
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      <Box sx={{ visibility: visibleCaptcha ? "visible" : "hidden" }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={process.env.REACT_APP_GOOGLE_RCV2_INV}
        />
      </Box>
    </>
  );
};

export default React.memo(AskPromptComponents);
